<template>
  <div class="pdf-wrap">
    <div
      class="full-loading"
      v-if="loading"
    >
      <div class="loading-sty1">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div
      class="export-pdf"
      @click="downPdf()"
    >导出PDF</div>
    <div id="pdf-dom">
      <div class="page home-page">
        <div class="title">
          <div class="project-name">{{someData.projectName}}</div>
          <div class="vice-plan">{{someData.decisionName}}</div>
          <div
            class="file-name"
            v-html="someData.str"
          ></div>
        </div>
        <div class="time">{{today}}</div>
      </div>
      <div class="page data-page">
        <div class="title">租赁决策总览</div>
        <ul
          class="overview-list"
          v-if="overviewList && overviewList.length"
        >
          <li>
            <div class="block-div"></div>
            <h2 class="num">{{overviewList[0].rent}}元</h2>
            <h3>{{overviewList[0].stepName}}租金目标</h3>
            <p>
              场均租金
              <span>{{overviewList[0].avgRent + unit}}</span>
            </p>
            <p>
              免租期扣除
              <span>{{overviewList[0].afterFree}}元</span>
            </p>
            <p class="hr"></p>
            <p
              v-for="(item, index) in overviewList"
              :key="index"
              v-show="index !== 0"
            >
              {{item.stepName}}
              <span>
                {{item.rent}}元
                <i
                  class="c-icon-up"
                  v-if="item.incomeUp && Number(item.incomeUp)>0"
                ></i>
                <i
                  class="c-icon-down"
                  v-if="item.incomeUp && Number(item.incomeUp)<0"
                ></i>
                {{item.rentUp?item.rentUp+'%':''}}
              </span>
            </p>
          </li>
          <li>
            <div class="block-div"></div>
            <h2 class="num">{{overviewList[0].cost}}元</h2>
            <h3>{{overviewList[0].stepName}}{{user.topuid === 6467 ? '物业管理费目标' : '综合管理费目标'}}</h3>
            <p>
              场均{{user.topuid === 6467 ? '物业' : '综合'}}管理费
              <span>{{overviewList[0].avgCost + unit}}</span>
            </p>
            <p class="hidden">.</p>
            <p class="hr"></p>
            <p
              v-for="(item, index) in overviewList"
              :key="index"
              v-show="index !== 0"
            >
              {{item.stepName}}
              <span>
                {{item.cost}}元
                <i
                  class="c-icon-up"
                  v-if="item.costUp && Number(item.costUp)>0"
                ></i>
                <i
                  class="c-icon-down"
                  v-if="item.costUp && Number(item.costUp)<0"
                ></i>
                {{item.costUp?item.costUp+'%':''}}
              </span>
            </p>
          </li>
          <li>
            <div class="block-div"></div>
            <h2 class="num">{{overviewList[0].income}}元</h2>
            <h3>{{overviewList[0].stepName}}收入目标</h3>
            <p>
              场均收入
              <span>{{overviewList[0].avgIncome + unit}}</span>
            </p>
            <p class="hidden">.</p>
            <p class="hr"></p>
            <p
              v-for="(item, index) in overviewList"
              :key="index"
              v-show="index !== 0"
            >
              {{item.stepName}}
              <span>
                {{item.income}}元
                <i
                  class="c-icon-up"
                  v-if="item.incomeUp && Number(item.incomeUp)>0"
                ></i>
                <i
                  class="c-icon-down"
                  v-if="item.incomeUp && Number(item.incomeUp)<0"
                ></i>
                {{item.incomeUp?item.incomeUp+'%':''}}
              </span>
            </p>
          </li>
        </ul>
      </div>
      <div class="page-wrap">
        <div class="content-panel">
          <div class="title">
            <div class="block"></div>
            <div class="text">租赁策略</div>
          </div>
          <ul
            class="fl data-box"
            v-if="hasDataCard"
          >
            <li
              v-for="item in brandCardList"
              :key="item.brandLevelType"
            >
              <div class="fl">{{item.brandLevelType}}级品牌</div>
              <div class="fr">
                <p class="num">{{item.brandLevelValue}}%</p>
                <ul>
                  <li
                    v-for="(ele,i) in item.brandGradeType"
                    :key="i"
                  >
                    <span>{{item.brandGradeValue[i] | notEmpty}}%</span>
                    {{['A', 'B', 'C', 'D'][i]}}级
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <div
            class="details-no-data fl"
            style="margin:230px 0 0 130px"
            v-else
          >
            <img
              src="@/assets/images/public/not-plandata.png"
              alt=""
            >
            <p>抱歉，暂无数据</p>
          </div>
          <div class="fr right-panel">
            <div class="fl">
              <div
                ref="formatDecision"
                class="pie-box store-decision"
                v-if="hasDataFormat"
              ></div>
              <div
                class="details-no-data mt80"
                v-else
              >
                <img
                  src="@/assets/images/public/not-plandata.png"
                  alt=""
                >
                <p>抱歉，暂无数据</p>
              </div>
            </div>
            <div class="fr">
              <div
                ref="storeDecision"
                class="pie-box store-decision"
                v-if="hasDataStore"
              ></div>
              <div
                class="details-no-data mt80"
                v-else
              >
                <img
                  src="@/assets/images/public/not-plandata.png"
                  alt=""
                >
                <p>抱歉，暂无数据</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="page-wrap"
        v-if="tbsFloorOverflow.length === 0"
      >
        <div class="content-panel">
          <div class="title">
            <div class="block"></div>
            <div class="text">租金计划</div>
          </div>
          <table v-if="tbsRentPlan[0] && tbsRentPlan[0].length">
            <thead>
              <tr>
                <th width='80'></th>
                <th>楼宇楼层</th>
                <th>建筑面积(㎡)</th>
                <th>面积占比(%)</th>
                <th>铺位数</th>
                <th>首阶段租金规模(元)</th>
                <th>场均租金{{`${unit&&'('+unit+')'}`}}</th>
                <th>免租期扣除(元)</th>
                <th>首阶段{{user.topuid === 6467 ? '物业' : '综合'}}管理费(元)</th>
                <th>首阶段收入目标(元)</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item,index) in tbsRentPlan[0]"
                :key="index"
              >
                <td
                  class="first-col"
                  :rowspan="tbsRentPlan[0].length"
                  v-if="index===0"
                >{{someData.projectName}}</td>
                <td>{{item.name}}</td>
                <td>{{item.buildingArea}}</td>
                <td>{{item.areaRate}}</td>
                <td>{{item.berthNums}}</td>
                <td>{{item.sumRent}}</td>
                <td>{{item.avgRent}}</td>
                <td>{{item.freeRent}}</td>
                <td>{{item.sumCost}}</td>
                <td>{{item.sumIncome}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <template v-else>
        <div
          class="page-wrap"
          v-for="item in tbsFloorOverflow"
          :key="item.name"
        >
          <div class="content-panel">
            <div class="title">
              <div class="block"></div>
              <div class="text">租金计划</div>
            </div>
            <table>
              <thead>
                <tr>
                  <th width='80'></th>
                  <th>楼宇楼层</th>
                  <th>建筑面积(㎡)</th>
                  <th>面积占比(%)</th>
                  <th>铺位数</th>
                  <th>首阶段租金规模(元)</th>
                  <th>场均租金{{`${unit&&'('+unit+')'}`}}</th>
                  <th>免租期扣除(元)</th>
                  <th>首阶段{{user.topuid === 6467 ? '物业' : '综合'}}管理费(元)</th>
                  <th>首阶段收入目标(元)</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(ele,index) in item"
                  :key="index"
                >
                  <td
                    class="first-col"
                    v-if="index===0"
                    :rowspan="item.length"
                  >{{someData.projectName}}</td>
                  <td>{{ele.name}}</td>
                  <td>{{ele.buildingArea}}</td>
                  <td>{{ele.areaRate}}</td>
                  <td>{{ele.berthNums}}</td>
                  <td>{{ele.sumRent}}</td>
                  <td>{{ele.avgRent}}</td>
                  <td>{{ele.freeRent}}</td>
                  <td>{{ele.sumCost}}</td>
                  <td>{{ele.sumIncome}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
      <div
        class="page-wrap"
        v-if="tbsFormatOverflow.length === 0"
      >
        <div class="content-panel">
          <div class="title">
            <div class="block"></div>
            <div class="text">租金计划</div>
          </div>
          <table>
            <thead>
              <tr>
                <th width='80'></th>
                <th>业态</th>
                <th>建筑面积(㎡)</th>
                <th>面积占比(%)</th>
                <th>铺位数</th>
                <th>首阶段租金规模(元)</th>
                <th>场均租金{{`${unit&&'('+unit+')'}`}}</th>
                <th>免租期扣除(元)</th>
                <th>首阶段{{user.topuid === 6467 ? '物业' : '综合'}}管理费(元)</th>
                <th>首阶段收入目标(元)</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item,index) in tbsRentPlan[1]"
                :key="index"
              >
                <td
                  class="first-col"
                  v-if="index===0"
                  :rowspan="tbsRentPlan[1].length"
                >{{someData.projectName}}</td>
                <td>{{item.name}}</td>
                <td>{{item.buildingArea}}</td>
                <td>{{item.areaRate}}</td>
                <td>{{item.berthNums}}</td>
                <td>{{item.sumRent}}</td>
                <td>{{item.avgRent}}</td>
                <td>{{item.freeRent}}</td>
                <td>{{item.sumCost}}</td>
                <td>{{item.sumIncome}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <template v-else>
        <div
          class="page-wrap"
          v-for="item in tbsFormatOverflow"
          :key="item.name"
        >
          <div class="content-panel">
            <div class="title">
              <div class="block"></div>
              <div class="text">租金计划</div>
            </div>
            <table>
              <thead>
                <tr>
                  <th width='80'></th>
                  <th>业态</th>
                  <th>建筑面积(㎡)</th>
                  <th>面积占比(%)</th>
                  <th>铺位数</th>
                  <th>首阶段租金规模(元)</th>
                  <th>场均租金{{`${unit&&'('+unit+')'}`}}</th>
                  <th>免租期扣除(元)</th>
                  <th>首阶段{{user.topuid === 6467 ? '物业' : '综合'}}管理费(元)</th>
                  <th>首阶段收入目标(元)</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(ele,index) in item"
                  :key="index"
                >
                  <td
                    class="first-col"
                    v-if="index===0"
                    :rowspan="item.length"
                  >{{someData.projectName}}</td>
                  <td>{{ele.name}}</td>
                  <td>{{ele.buildingArea}}</td>
                  <td>{{ele.areaRate}}</td>
                  <td>{{ele.berthNums}}</td>
                  <td>{{ele.sumRent}}</td>
                  <td>{{ele.avgRent}}</td>
                  <td>{{ele.freeRent}}</td>
                  <td>{{ele.sumCost}}</td>
                  <td>{{ele.sumIncome}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
      <div class="page-wrap">
        <div class="content-panel">
          <div class="title">
            <div class="block"></div>
            <div class="text">租金计划</div>
          </div>
          <table>
            <thead>
              <tr>
                <th width='80'></th>
                <th>业态</th>
                <th>建筑面积(㎡)</th>
                <th>面积占比(%)</th>
                <th>铺位数</th>
                <th>首阶段租金规模(元)</th>
                <th>场均租金{{`${unit&&'('+unit+')'}`}}</th>
                <th>免租期扣除(元)</th>
                <th>首阶段{{user.topuid === 6467 ? '物业' : '综合'}}管理费(元)</th>
                <th>首阶段收入目标(元)</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item,index) in tbsRentPlan[2]"
                :key="index"
              >
                <td
                  class="first-col"
                  v-if="index===0"
                  :rowspan="tbsRentPlan[2].length"
                >{{someData.projectName}}</td>
                <td>{{item.name}}</td>
                <td>{{item.buildingArea}}</td>
                <td>{{item.areaRate}}</td>
                <td>{{item.berthNums}}</td>
                <td>{{item.sumRent}}</td>
                <td>{{item.avgRent}}</td>
                <td>{{item.freeRent}}</td>
                <td>{{item.sumCost}}</td>
                <td>{{item.sumIncome}}</td>
              </tr>
            </tbody>
          </table>
          <table>
            <thead>
              <tr>
                <th
                  width='80'
                  colspan="2"
                ></th>
                <th>首阶段租金规模(元)</th>
                <th>场均租金{{`${unit&&'('+unit+')'}`}}</th>
                <th>免租期扣除(元)</th>
                <th>首阶段{{user.topuid === 6467 ? '物业' : '综合'}}管理费(元)</th>
                <th>首阶段收入目标(元)</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item,index) in tbsStoreMore"
                :key="index"
              >
                <td
                  class="first-col"
                  v-if="index===0"
                  :rowspan="tbsStoreMore.length"
                >{{someData.projectName}}</td>
                <td>{{item.name}}</td>
                <td>{{item.sumRentTarget}}</td>
                <td>{{item.avgRent}}</td>
                <td>{{item.sumFreeTarget}}</td>
                <td>{{item.sumCostTarget}}</td>
                <td>{{item.sumIncomeTarget}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        class="page-wrap"
        v-for="(item, index) in tbsLandingFloor"
        :key="index"
      >
        <div class="content-panel">
          <div class="title">
            <div class="block"></div>
            <div class="text">落位计划</div>
          </div>
          <table>
            <thead>
              <tr>
                <th width='80'></th>
                <th>楼宇楼层</th>
                <th>已落位面积</th>
                <th>落位进度</th>
                <th>已落位铺位数</th>
                <th>已落位品牌数</th>
                <!-- <th>覆盖业态</th> -->
                <th>品牌级次</th>
                <th>品牌数量</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(ele,i) in item"
                :key="i"
              >
                <td
                  class="first-col"
                  v-if="i===0"
                  :rowspan="item.length"
                >{{someData.projectName}}</td>
                <td>{{ele.name || '-'}}</td>
                <td>{{ele.area || '-'}}</td>
                <td>{{ele.areaProgress || '-'}}</td>
                <td>{{ele.berthNums || '-'}}</td>
                <td>{{ele.brandNums || '-'}}</td>
                <!-- <td>{{ele.industryNums || '-'}}</td> -->
                <td>
                  <ul :class="i%2===0?'like-tbs-even':'like-tbs-odd'">
                    <li
                      v-for="(ele1, i1) in ele.brandLevels"
                      :key="i1"
                    >
                      {{ele1.name || '-'}}
                    </li>
                  </ul>
                </td>
                <td>
                  <ul :class="i%2===0?'like-tbs-even':'like-tbs-odd'">
                    <li
                      v-for="(ele1, i1) in ele.brandLevels"
                      :key="i1"
                    >
                      {{ele1.num || '-'}}
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        class="page-wrap"
        v-for="(item, index) in tbsLandingFormat"
        :key="index"
      >
        <div class="content-panel">
          <div class="title">
            <div class="block"></div>
            <div class="text">落位计划</div>
          </div>
          <table>
            <thead>
              <tr>
                <th width='80'></th>
                <th>业态</th>
                <th>已落位面积</th>
                <th>落位进度</th>
                <th>已落位铺位数</th>
                <th>已落位品牌数</th>
                <!-- <th>覆盖业态</th> -->
                <th>品牌级次</th>
                <th>品牌数量</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(ele,i) in item"
                :key="i"
              >
                <td
                  class="first-col"
                  v-if="i===0"
                  :rowspan="item.length"
                >{{someData.projectName}}</td>
                <td>{{ele.name || '-'}}</td>
                <td>{{ele.area || '-'}}</td>
                <td>{{ele.areaProgress || '-'}}</td>
                <td>{{ele.berthNums || '-'}}</td>
                <td>{{ele.brandNums || '-'}}</td>
                <!-- <td>{{ele.industryNums || '-'}}</td> -->
                <td>
                  <ul :class="i%2===0?'like-tbs-even':'like-tbs-odd'">
                    <li
                      v-for="(ele1, i1) in ele.brandLevels"
                      :key="i1"
                    >
                      {{ele1.name || '-'}}
                    </li>
                  </ul>
                </td>
                <td>
                  <ul :class="i%2===0?'like-tbs-even':'like-tbs-odd'">
                    <li
                      v-for="(ele1, i1) in ele.brandLevels"
                      :key="i1"
                    >
                      {{ele1.num || '-'}}
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        class="page-wrap"
        v-for="(item, index) in tbsLandingStore"
        :key="index"
      >
        <div class="content-panel">
          <div class="title">
            <div class="block"></div>
            <div class="text">落位计划</div>
          </div>
          <table>
            <thead>
              <tr>
                <th width='80'></th>
                <th>主次力店</th>
                <th>已落位面积</th>
                <th>落位进度</th>
                <th>已落位铺位数</th>
                <th>已落位品牌数</th>
                <!-- <th>覆盖业态</th> -->
                <th>品牌级次</th>
                <th>品牌数量</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(ele,i) in item"
                :key="i"
              >
                <td
                  class="first-col"
                  v-if="i===0"
                  :rowspan="item.length"
                >{{someData.projectName}}</td>
                <td>{{ele.name || '-'}}</td>
                <td>{{ele.area || '-'}}</td>
                <td>{{ele.areaProgress || '-'}}</td>
                <td>{{ele.berthNums || '-'}}</td>
                <td>{{ele.brandNums || '-'}}</td>
                <!-- <td>{{ele.industryNums || '-'}}</td> -->
                <td>
                  <ul :class="i%2===0?'like-tbs-even':'like-tbs-odd'">
                    <li
                      v-for="(ele1, i1) in ele.brandLevels"
                      :key="i1"
                    >
                      {{ele1.name || '-'}}
                    </li>
                  </ul>
                </td>
                <td>
                  <ul :class="i%2===0?'like-tbs-even':'like-tbs-odd'">
                    <li
                      v-for="(ele1, i1) in ele.brandLevels"
                      :key="i1"
                    >
                      {{ele1.num || '-'}}
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
import echarts from 'echarts'
export default {
  data () {
    return {
      loading: false,
      unit: '',
      projectName: '',
      picType: 0,
      decisionId: 1,
      decisionName: '',
      buildingNames: '',
      effectDate: '',
      overviewList: [],
      brandCardList: [],
      iconList: [],
      floorLit: [],
      tbsRentPlan: [[], [], []],
      tbsFloorOverflow: [], // 租金计划品牌表格如果超出长度则用这个数组
      tbsFormatOverflow: [], // 租金计划业态表格如果超出长度则用这个数组
      tbsLandingFloor: [],
      tbsLandingFormat: [],
      tbsLandingStore: [],
      tbsStoreMore: [],
      hasDataCard: true,
      hasDataFormat: true,
      hasDataStore: true,
      pdfName: '111111.pdf',
      today: dayjs().format('YYYY-MM-DD'),
      processOverview: {},
      someData: {}
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      token: 'token'
    })
  },
  filters: {
    notEmpty: (val) => {
      if (!val && val !== 0) {
        return '-'
      } else {
        return val
      }
    }
  },
  mounted () {
    // console.log(this.user)
    this.projectId = this.$route.query.id
    this.picType = this.$route.query.picType
    this.projectName = localStorage.getItem('projectName')
    this.decisionName = localStorage.getItem('decisionName')
    this.buildingNames = localStorage.getItem('buildingNames')
    this.effectDate = localStorage.getItem('effectDate')
    this.decisionId = this.$route.query.decisionId
    this.getPdfHeaderData()
    this.getDataBrand()
    this.getDataFormat()
    this.getDataStore()
    this.getDataStoreMore()
    this.handleRentPlan(1)
    this.handleRentPlan(2)
    this.handleRentPlan(3)
    this.getDataLanding(1)
    this.getDataLanding(2)
    this.getDataLanding(3)
    this.getSomeData()
  },
  methods: {
    getSomeData () {
      this.axios.get(api.decisionsExportNum + this.decisionId, {}).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          console.log('resData', resData)
          this.someData = resData
          this.someData.str = resData.buildingNames + '&nbsp;&nbsp;&nbsp;' + resData.effectStartDate + ' ~ ' + resData.effectEndDate
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataLanding (type) {
      const params = {
        decisionId: this.decisionId,
        type: type
      }
      this.axios.get(api.berthPlanList, { params: params }).then(res => { // berthPlan/listAndLoop获取落位计划列表和环图
        if (res.data.code === 0) {
          const resData = res.data.data
          let list = []
          if (resData) {
            if (resData && resData.length) {
              list = resData.map(item => {
                item.area += '㎡'
                item.areaProgress += '%'
                return item
              })
            }
            const len = Math.ceil(list.length / 12)
            for (let i = 0; i < len; i++) {
              if (type === 1) {
                this.tbsLandingFloor.push(list.slice((i) * 12, 12 * (i + 1)))
                console.log('tbsLandingFloor', this.tbsLandingFloor)
              }
              if (type === 2) {
                this.tbsLandingFormat.push(list.slice((i) * 12, 12 * (i + 1)))
                console.log('tbsLandingFormat', this.tbsLandingFormat)
              }
              if (type === 3) {
                this.tbsLandingStore.push(list.slice((i) * 12, 12 * (i + 1)))
                console.log('tbsLandingStore', this.tbsLandingStore)
              }
            }
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataStoreMore () {
      const params = {
        decisionId: this.decisionId
      }
      this.axios.get(api.allPriceIncomeScale, { params: params }).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          const list = ['首阶段市场价收入规模', '首阶段标准价收入规模', '首阶段保底价收入规模']
          this.tbsStoreMore = resData.map((item, index) => {
            item.name = list[index]
            return item
          })
          console.log('resData', resData)
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    handleRentPlan (type) {
      const params = {
        decisionId: this.decisionId,
        type: type
      }
      this.axios.get(api.decisionsRentList, { params: params }).then(res => { // /rentPlan/list 列表
        if (res.data.code === 0) {
          const resData = res.data.data
          let list = []
          if (resData.length) {
            list = resData.map(item => {
              return item
            })
            if (list.length > 12) {
              const len = Math.ceil(list.length / 12)
              for (let i = 0; i < len; i++) {
                if (type === 1) {
                  this.tbsFloorOverflow.push(list.slice((i) * 12, 12 * (i + 1)))
                }
                if (type === 2) {
                  this.tbsFormatOverflow.push(list.slice((i) * 12, 12 * (i + 1)))
                }
              }
            }
          } else {
            list.push({
              areaRate: '-',
              avgRent: '-',
              berthNums: '-',
              buildingArea: '-',
              freeRent: '-',
              industryNums: '-',
              name: '-',
              sumCost: '-',
              sumIncome: '-',
              sumRent: '-'
            })
          }
          this.$set(this.tbsRentPlan, (type - 1), list)
          console.log('tbsFloorOverflow', this.tbsFloorOverflow)
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataStore () {
      const params = {
        decisionId: this.decisionId
      }
      this.axios.get(api.getStorePie, { params: params }).then(res => { // berthLoop', // 获取主次力店策略环图 GET
        if (res.data.code === 0) {
          const resData1 = res.data.data
          if (resData1 && resData1.labels && resData1.labels.length) {
            this.$nextTick(() => {
              this.createPie('storeDecision', 1, resData1)
            })
            this.hasDataStore = true
          } else {
            this.hasDataStore = false
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    createPie (box, hasLegend, resData) {
      // box 容器ref值
      // hasLegend 是否设置legend
      // resData数据
      let emphasisFormat = []
      if (box === 'brandDecision') {
        emphasisFormat = [
          '{a|{c}%}',
          '{b|{b}品牌}'
        ]
      } else if (box === 'landingPlaned' || box === 'storeDecision') {
        emphasisFormat = [
          '{a|{d}%}',
          '{b|{b}: {c}㎡}'
        ]
      } else if (box === 'formatDecision') {
        emphasisFormat = [
          '{a|{d}%}',
          '{b|{b}}',
          '{b|{c}㎡}'
        ]
      } else {
        emphasisFormat = [
          '{a|{d}%}',
          '{b|{b}: {c}万元}'
        ]
      }
      const seriesData = []
      for (let i = 0; i < resData.labels.length; i++) {
        seriesData.push(
          {
            value: resData.values[i],
            name: resData.labels[i]
          }
        )
      }
      let maxItem = seriesData[0]
      for (let i = 0; i < seriesData.length; i++) {
        if (Number(maxItem.value) < Number(seriesData[i].value)) {
          maxItem = seriesData[i]
        }
      }
      let chart = null
      if (chart) {
        chart.dispose()
      }
      if (!this.$refs[box]) {
        return
      }
      chart = echarts.init(this.$refs[box])
      chart.setOption({
        tooltip: {
          show: false,
          trigger: 'item'
        },
        legend: {
          show: hasLegend,
          bottom: '0',
          textStyle: {
            color: '#808191'
          },
          left: 'center'
        },
        color: ['#32538f', '#385da1', '#3e68b2', '#4974c5', '#6e8acb', '#a2b1da'],
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['115', '130'],
            legendHoverLink: false, // 移入leged不显示在中间
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: 'center', // 设置居中
                formatter: function (data) { // 设置圆饼图中间文字排版
                  return data.name + '\n' + data.value // 对应的名字和值
                },
                textStyle: {
                  fontSize: '0'
                }
              }
            },
            emphasis: {
              label: {
                show: true,
                formatter: emphasisFormat.join('\n'),
                rich: {
                  a: {
                    color: '#000',
                    fontSize: 30
                  },
                  b: {
                    color: '#000',
                    fontSize: 14
                  }
                }
              }
            },
            labelLine: {
              show: false
            },
            data: seriesData
          }
        ]
      })
      chart.dispatchAction({
        type: 'highlight',
        name: maxItem.name
      })
      let currentName = maxItem.name
      const validNumList = [] // 非零的项集合
      for (let i = 0; i < seriesData.length; i++) {
        const item = seriesData[i]
        if (item.value) {
          validNumList.push(item.name)
        }
      }
      let selectedList = []
      chart.on('legendselectchanged', params => {
        selectedList = []
        chart.dispatchAction({
          type: 'downplay',
          name: currentName
        })
        for (const key in params.selected) {
          if (params.selected[key]) {
            selectedList.push(key)
          }
        }
        if (selectedList.length) {
          if (validNumList.length) {
            const validSelectedList = [] // legend中选中的非零的项集合
            for (let i = 0; i < validNumList.length; i++) {
              const item = validNumList[i]
              if (selectedList.includes(item)) {
                validSelectedList.push(item)
              }
            }
            if (validSelectedList.length) {
              currentName = validSelectedList[0]
            } else {
              currentName = selectedList[0]
            }
          } else {
            currentName = selectedList[0]
          }
          chart.dispatchAction({
            type: 'highlight',
            name: currentName
          })
        }
      })
      chart.on('mouseover', params => {
        chart.dispatchAction({
          type: 'downplay',
          name: currentName
        })
        currentName = params.name
        chart.dispatchAction({
          type: 'highlight',
          name: params.name
        })
      })
      // 鼠标移出
      chart.on('mouseout', params => {
        chart.dispatchAction({ // 持续高亮
          type: 'highlight',
          name: currentName
        })
      })
      window.addEventListener('resize', () => {
        chart.resize()
      })
    },
    getDataFormat () {
      const params = {
        decisionId: this.decisionId
      }
      this.axios.get(api.getFormatDecisionsPie, { params: params }).then(res => {
        if (res.data.code === 0) {
          const resData1 = res.data.data
          if (resData1 && resData1.labels && resData1.labels.length) {
            if (resData1.labels.length === 1 && resData1.labels[0] === '未定义') {
              this.hasDataFormat = false
            } else {
              this.$nextTick(() => {
                this.createPie('formatDecision', 1, resData1)
              })
              this.hasDataFormat = true
            }
          } else {
            this.hasDataFormat = false
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getPdfHeaderData () {
      const params = {
        decisionId: this.decisionId
      }
      this.axios.get(api.decisionsOverview, { params: params }).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          resData && resData.length && (this.overviewList = resData)
          resData && resData.length && (this.unit = ['', '元/日/㎡', '元/月/㎡'][resData[0].rentUnit])
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataBrand () {
      const params = {
        decisionId: this.decisionId
      }
      this.axios.get(api.handleBrandDecisions, { params: params }).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          console.log('resData', resData)
          if (resData.brandLevels && resData.brandLevels.length) {
            this.brandCardList = resData.brandLevels
            this.hasDataCard = true
          } else {
            this.hasDataCard = false
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getBerthTaskOverviewFunc () {
      this.axios.post(api.getBerthTaskOverview, { projectId: this.projectId })
        .then((res) => {
          this.processOverview = res.data.data
        })
    },
    getPdfMap () {
      const data = {}
      data.projectId = this.projectId
      data.decisionId = this.decisionId
      this.axios.post(api.exportRentPlan, data)
        .then((res) => {
          this.floorLit = res.data.data.reverse()
        })
    },
    downPdf () {
      this.loading = true
      document.body.style.overflowY = 'hidden'
      this.axios.get(api.exportReportCtr + `?domain=${document.domain}&id=0&token=${this.token}&uid=0&projectId=${this.projectId}&decisionId=${this.decisionId}&from=0&picType=${this.picType}&pagename=leasingDecisionsPDF`, {
        headers: {
          token: this.token
        },
        responseType: 'blob'
      })
        .then((res) => {
          console.log('res', res)
          const _res = res.data
          const blob = new Blob([_res], { type: 'application/pdf' })
          // FileReader主要用于将文件内容读入内存
          const reader = new FileReader()
          reader.readAsDataURL(blob)
          // onload当读取操作成功完成时调用
          reader.onload = () => {
            const a = document.createElement('a')
            // 获取文件名fileName
            const fileName = this.someData.projectName + this.someData.decisionName
            a.download = fileName
            a.href = URL.createObjectURL(blob)
            document.body.appendChild(a)
            a.click()
            setTimeout(() => {
              URL.revokeObjectURL(a.href) // 释放URL 对象
              document.body.removeChild(a)
            }, 1000)
          }
          this.loading = false
          document.body.style.overflowY = 'auto'
        })
    }
  }
}
</script>

<style lang="stylus" scoped>
.details-no-data
  p
    margin-top 30px
    color #000
.page
  height 1084px
  // margin-bottom 10px
.pdf-wrap
  background-color #fff
  height 100%
  .home-page
    width 100%
    // height 100%
    background url('~@/assets/images/pdf/pdf-home.png') no-repeat
    background-size 100% 100%
    position relative
    .title
      position absolute
      left 35%
      top 30%
      color #3265fb
      font-weight bold
      .project-name
        font-size 60px
      .vice-plan
        font-size 60px
    .time
      position absolute
      right 10%
      bottom 80px
      font-size 22px
      color #3265fb
  .data-page
    width 100%
    background url('~@/assets/images/pdf/pdf-bg.png') no-repeat
    background-size 100% 50%
    position relative
    .title
      position absolute
      left 10%
      top 70px
      font-size 60px
      color #fff
      font-weight bold
    .overview-list
      display flex
      width 90%
      margin 0 auto
      height 1084px
      justify-content center
      align-items center
      li
        display inline-block
        width 30%
        min-height 430px
        background-color #fff
        border-radius 2px
        box-shadow 0 5px 20px 0 rgba(0, 0, 0, 0.1)
        margin 0 30px
        position relative
        .block-div
          width 32px
          height 32px
          background-color #3265fb
          margin 40px auto
          position relative
          &:after
            content ''
            width 16px
            height 16px
            background-color #fff
            position absolute
            left 0
            top 0
        .num
          font-size 50px
          font-weight bold
          text-align center
          color #3265fb
        h3
          background-color #2262ff
          width 80%
          height 60px
          line-height 60px
          font-size 32px
          text-align center
          color #fff
          margin 30px auto
        p.hr
          height 1px
          width 80%
          margin 0 auto
          background-color #2262ff
          &:before
            display none
        p.hidden
          overflow hidden
          position relative
          top 999px
          &:before
            display none
        p
          width 80%
          margin 20px auto
          font-weight bold
          position relative
          text-indent 20px
          span
            float right
            font-weight 500
          &:before
            content ''
            display inline-block
            width 5px
            height 5px
            border-radius 5px
            background #000
            position absolute
            top 8px
            left 2px
.page-wrap
  width 100%
  height 1084px
  background url('~@/assets/images/pdf/pdf-bg.png') no-repeat
  background-size 100% 100%
  padding 60px
  .content-panel
    padding 44px 50px
    width 100%
    height 100%
    background-color #fff
    position relative
    .like-tbs-even
      width 100%
      li
        height 26px
        line-height 26px
        &:nth-child(odd)
          background-color #d3dfee
        &:nth-child(even)
          background-color #eaeff7
    .like-tbs-odd
      width 100%
      li
        height 26px
        line-height 26px
        &:nth-child(odd)
          background-color #eaeff7
        &:nth-child(even)
          background-color #d3dfee
    table
      width 100%
      margin-top 10px
      margin-left -2px
      td, th
        height 60px
      thead
        tr
          th
            text-align center
            background-color #7d96d1
            color #fff
            font-size 16px
            border-right 2px solid #fff
            border-bottom 2px solid #fff
      tbody
        tr
          border-bottom 3px solid #fff
          &:nth-child(odd)
            background-color #d3dfee
            div
              &:nth-child(odd)
                background-color #d3dfee
              &:nth-child(even)
                background-color #eaeff7
          &:nth-child(even)
            background-color #eaeff7
            div
              &:nth-child(odd)
                background-color #eaeff7
              &:nth-child(even)
                background-color #d3dfee
          td
            text-align center
            border-right 2px solid #fff
            border-bottom 2px solid #fff
            color #333
            font-size 14px
            font-weight bold
            &.first-col
              background-color #7d96d1
            div
              height 25px
              line-height 25px
              border-right 2px solid #fff
              border-bottom 2px solid #fff
              &:last-child
                border-bottom none
    .data-box
      margin 78px 0 0 26px
      &>li
        width 445px
        margin-bottom 17px
        overflow hidden
        .fl
          width 113px
          height 130px
          background #2261ff
          font-size 27px
          color #fff
          padding-top 40px
          text-align center
          box-sizing border-box
        .fr
          width 332px
          height 130px
          border 1px dashed #000
          .num
            text-align center
            font-size 35px
            color #2261ff
            margin 32px 0
          ul
            li
              display inline-block
              font-size 20px
              width 25%
              text-align center
              span
                display block
                margin-bottom 15px
    .right-panel
      overflow hidden
      width calc(100% - 490px)
      margin-top 130px
      .fl, .fr
        width 50%
      .pie-box
        width 100%
        height 450px
    .title
      display flex
      align-items center
      .block
        width 32px
        height 32px
        background-color #3b65fc
      .text
        font-size 32px
        margin-left 10px
        color #333
        font-weight bold
.export-pdf
  position fixed
  right 20px
  top 20px
  width 120px
  height 36px
  background-color #3b65fc
  color #fff
  text-align center
  line-height 36px
  font-size 20px
  border-radius 3px
  z-index 111
  cursor pointer
.plan-icon-list
  font-size 14px
  color #333
  font-weight 500
  text-align center
  position relative
  margin-top -40px
  img
    margin-right 5px
    margin-left 10px
    width 18px
    height 18px
    vertical-align middle
.map-font-color
  position absolute
  bottom 30px
  left 50%
  text-align center
  color #333
  font-size 14px
  font-weight 500
  margin-left -286px
  span
    margin 0 8px
    i
      display inline-block
      width 12px
      height 12px
      border-radius 6px
      vertical-align middle
      margin-right 4px
</style>
